<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '@/helpers/errorHandler';
import Uploader from "@/components/Quizes/uploader";
/**
 * Starter component
 */
export default {
  page: {
    title: `Question Form`,
  },
  data() {
    return {
      title: "Questions",
      baseUrl: process.env.VUE_APP_BASE_URL,
      choice: null,
      answerError: null,
      answerOptions:[
        {
          text:'First answer',
          value: 'ans_one'
        },
        {
          text:'Second answer',
          value: 'ans_two'
        },
        {
          text:'Third answer',
          value: 'ans_three'
        },
        {
          text:'Forth answer',
          value: 'ans_four'
        },
        {
          text:'Fifth answer',
          value: 'ans_five'
        },

      ],
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Quizzes",
          href: "/quizes",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Uploader
  },
  methods:{
    validate(){
      if(this.choice){
        this.answerError = true;
      }else{
        this.answerError = false;
      }
      return this.answerError;
    },
    clearSelectError3(){
      this.answerError = null;
    },
    async handleSubmit() {
      let check = await this.validate();
      if( check){
        this.$store.dispatch("quiz/deleteAnswerImage",{quiz:this.$route.params.question,choice:this.choice}).then(
           ()=>{
            errorHandler.methods.successful(`Image deleted successfully!`)
            this.$router.push(`/quizes/${this.$route.params.quiz}/details`);
          }
        ).catch((err)=>{
          errorHandler.methods.errorMessage(err);
        });
      }
    },
    clearSelectError3(){
      this.answerError = null;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Upload Image Question</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="">
                    <div class="col-md-6" >
                      <b-form-group label="Choice:" label-for="input-C" >
                        <b-form-select v-model="choice" :options="this.answerOptions" :state="answerError" @change="clearSelectError3"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit()">Delete</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>